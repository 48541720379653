<template>
  <b-container>
    <b-row>
      <b-col
        lg="10"
        sm="10"
        offset-lg="1"
      >
        <b-card
          class="hubu-bg-red text-center"
          :header="user.first_name + ' ' + user.last_name"
          header-tag="header"
        >
          <b-row class="mt-5 text-left">
            <b-col
              lg="6"
              sm="6"
            >
              <span class="text-bold">{{ getText('full_name') }}: </span><span>{{ user.first_name }} {{ user.last_name }}</span>
            </b-col>
            <b-col
              lg="6"
              sm="6"
            >
              <span class="text-bold">{{ getText('email') }}: </span><span>{{ user.email }}</span>
            </b-col>
          </b-row>

          <b-row class="mt-5 text-left">
            <b-col
              lg="6"
              sm="6"
            >
              <span class="text-bold">{{ getText('status') }}: </span><span>{{ (user.confirmed == true ? 'Active' : 'Inactive') }}</span>
            </b-col>
            <b-col
              lg="6"
              sm="6"
            >
              <span class="text-bold">{{ getText('number_of_games_played') }}: </span><span>{{ gamesPerHat[user.hats[0].id] || 0 }}</span>
            </b-col>
          </b-row>

          <b-row class="mt-5 text-left">
            <b-col
              lg="6"
              sm="6"
            >
              <span class="text-bold">Role: </span><span>{{ user.hats[0] ? $t('roles_display')[user.hats[0].role.toLowerCase()] : "" }}</span>
            </b-col>
          </b-row>

          <hr />

          <b-row class="text-left">
            <b-col
              lg="6"
              sm="6"
            >
              <span class="text-bold">{{ getText('reset_password') }}</span>
            </b-col>
          </b-row>

          <b-row class="text-left mt-3">
            <b-col
              lg="12"
              sm="12"
            >
              <span>{{ getText('you_can_help') }} <span class="text-bold">{{ user.first_name }} {{ user.last_name }}</span> {{ getText('you_can_help_description') }} </span>
              <span class="text-bold">{{ user.email }}</span>
            </b-col>
          </b-row>

          <b-row class="text-left mt-3">
            <b-col
              lg="12"
              sm="12"
              @click="() => (!hasReset && forgot())"
            >
              <b-button :disabled="hasReset">
                {{ !hasReset ? getText('email_instructions') : $t('sent') }}
              </b-button>
            </b-col>
          </b-row>

          <b-row class="text-center mt-5">
            <b-col
              lg="12"
              sm="12"
            >
              <b-button
                class="btn-red"
                @click="deleteUser"
              >
                {{ getText('delete_user') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ManageAccount",
  data (){
    return {
      gamesPlayed: null,
      hasReset: false,
    };
  },
  computed: {
    ...mapGetters(["gamesPerHat"]),
    user () {
      return this.$store.getters.users.find(user => user.id == this.$route.params.user.id);
    },
    organization () {
      return this.$store.getters.getOrganization;
    },
  },
  mounted () {
    this.gamesPlayed = this.$store.dispatch("gamesPlayed");
  },
  methods: {
    ...mapActions(["forgotPassword"]),
    forgot () {
      this.hasReset = true;
      this.forgotPassword({ email: this.user.email });
    },
    getText (str) {
      return this.$store.getters.getAppTexts[str].content;
    },
    async deleteUser () {
      await this.$store.dispatch("deleteUser", { id: this.user.hats[0].id });
      this.$router.push({ name: "ManageAccounts", params: { lang: this.$i18n.locale } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}

.text-blue {
  color: rgb(74, 113, 241) !important;
}

.text-bold {
  font-weight: bold;
}
</style>
